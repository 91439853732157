site = site || {};
site.caCookieLegal = site.caCookieLegal || {};

(function($, generic, site) {

"use strict";

site.caCookieLegal = {
  conf: {
    popover_display_modal_mask : 1,
    display_po_immediately     : 0,
    homepage                   : 1,
    popover_filename           : '/promotions/includes/popovers/cookie_legal.tmpl',
    popover_style_left         : '0px',
    callback_name              : 'undefined',
    pop_up_count               : 'cookie_legal_count',
    daily_reset                : 'cookie_legal_day_counter',
    set_popover_cookie_only    : 0,
    close_popup_timer          : 20000,
    animation_time             : 600,
  },

  initConf: function() {
    if (this.conf.popover_filename) {
      this.process();
    }
  },

  init: function() {
    try {
      this.initConf();
    }
    catch(err) {
      // console.log(err);
    }
  },

  process: function () {
    var siteWidePopOverPendingOrVisible = true;
    var self = this,
        conf = this.conf;

    if ((generic.cookie(conf.pop_up_count) > 1) || conf.set_popover_cookie_only  || generic.cookie(conf.daily_reset))  {
      return;
    }

    var url = conf.popover_filename;
    var includeBackgroundFlag = conf.display_po_immediately;

    $.ajax(url, {
      dataType: 'html',
      success: function(html) {
        var time_to_wait = 2500;

        window.setTimeout(function() {

          var popover_div = $('<div id="cookie_popover_container" class="cookie_legal"></div>').html(html).get(0);

          $("body").append(popover_div);
          $(popover_div).slideDown(conf.animation_time);
          $(".cookie_legal-close_box").click(function(){
             $(popover_div).slideUp(conf.animation_time);
          })

          setTimeout(function(){
            $(popover_div).slideUp(conf.animation_time);
          }, conf.close_popup_timer)

          self.setCookie();
        }, time_to_wait);
      }
    });
  },

  setCookie : function() {
    var conf = this.conf;
    
    if (! generic.cookie(conf.pop_up_count)){
      generic.cookie(conf.pop_up_count, 0,  { path: '/' });
    }

    //sets daily reset cookie to expire in 1 day.
    var expDate = new Date();
    expDate.setDate(expDate.getDate() + 1);
    var expDateString = expDate.toGMTString();

    generic.cookie(conf.daily_reset, 1, {path: '/', expires: expDateString})

    generic.cookie(conf.pop_up_count, generic.cookie(conf.pop_up_count) + 1,  { path: '/' });
  },

  close_sitewide_popover: function() {
    siteWidePopOverPendingOrVisible = false;
    generic.overlay.hide();
  },
  
};


// Use standard Drupal behaviors to initialize things
Drupal.behaviors.caCookieLegal = {
  attach: function (context, settings) {
    if (this.attached) {
      return;
    }
    this.attached = true;

    site.caCookieLegal.init();
  },

  attached: false
};

})(jQuery, window.generic || {}, window.site || {});
